<template>
  <div class="home">
    <button class="button field is-info is-light" @click="isCardModalActive = true">
      <b-icon icon="plus"></b-icon>
      <span>Novo passageiro</span>
    </button>
    <b-table :data="allPassengers" ref="table" paginated per-page="10" detailed detail-key="id" :striped="true"
      :debounce-search="1000" @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes de ${row.name}`)
      " :show-detail-icon="true" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
      aria-current-label="Current page">
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column field="name" label="Nome" :searchable="true" sortable v-slot="props">
        <template v-if="showDetailIcon">{{
          props.row.name.toUpperCase()
        }}</template>
        <template v-else>
          <a :style="{
            color:
              props.row.blocked === 0
                ? 'green' + ' !important'
                : 'red' + ' !important',
          }" @click="props.toggleDetails(props.row)">{{ props.row.name.toUpperCase() }}</a>
        </template>
      </b-table-column>

      <b-table-column field="registration" label="Matrícula" :searchable="true" sortable v-slot="props">{{
        props.row.registration.toUpperCase() }}</b-table-column>

      <b-table-column field="customerName" label="Cliente" sortable :searchable="true" v-slot="props">{{
        props.row.customerName.toUpperCase() }}</b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.name.toUpperCase() }}
                  {{ props.row.last_name.toUpperCase() }}</strong>
                <br />
                <strong>Matrícula: </strong>
                <small>{{ props.row.registration.toUpperCase() }}</small>
                <br />
                <span v-if="props.row.phone_number">
                  <strong>Celular: </strong>
                  <small>{{ props.row.phone_number }}</small>
                  <br />
                </span>
                <strong>Endereço: </strong>
                <small>{{ props.row.address.toUpperCase() }}</small>
                <br />
                <strong>Cidade: </strong>
                <small>{{ props.row.city.toUpperCase() }}</small>
                <br />
                <strong>Estado: </strong>
                <small>{{ props.row.state.toUpperCase() }}</small>
                <br />
                <strong>Cliente: </strong>
                <small>{{ props.row.customer.name.toUpperCase() }}</small>
                <br />
                <strong>Observação: </strong>
                <small>{{ props.row.observation.toUpperCase() }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
      <b-table-column v-slot="props" label="Opções">
        <button class="button is-small is-danger" @click.prevent="onBlockOrUnblock(props.row)">
          <b-icon icon="cancel" size="is-small"></b-icon>
        </button>
        <button class="button is-small is-light" @click.prevent="
          onEdit(props.row)
        isCardModalActive = true
          ">
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button class="button is-small is-danger" @click.prevent="onDelete(props.row.id)">
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome">
              <b-input v-model="model.name" required></b-input>
            </b-field>
            <b-field label="Matrícula">
              <b-input v-model="model.registration" required></b-input>
            </b-field>
            <b-field label="RG">
              <b-input v-model="model.rg" required></b-input>
            </b-field>
            <b-field label="Celular">
              <b-input v-model="model.phone_number"></b-input>
            </b-field>
            <!-- <b-field label="Endereço">
              <b-input v-model="model.address" required></b-input>
            </b-field> -->
            <b-field label="Endereço">
              <b-autocomplete :data="listAddress" placeholder="Pesquisar endereço..." field="address"
                :loading="dataset.loading" @typing="getAddressByName" v-model="model.address"
                @select="option => model.address_iid = option.placeId">

                <template slot-scope="props">
                  {{ props.option.address }}
                </template>

              </b-autocomplete>
            </b-field>
            <!-- <b-field label="Número">
              <b-input v-model="model.number" required></b-input>
            </b-field> -->
            <b-field label="Latitude">
              <b-input v-model="model.address_lat"></b-input>
            </b-field>

            <b-field label="Longitude">
              <b-input v-model="model.address_lng"></b-input>
            </b-field>

            <b-field label="Complemento">
              <b-input v-model="model.complement"></b-input>
            </b-field>
            <!-- <b-field label="Bairro">
              <b-input v-model="model.district" required></b-input>
            </b-field> -->
            <!-- <b-field label="Estado">
              <b-select @input="statesCities" placeholder="Selecione um estado" required v-model="model.state">
                <option v-for="(item, index) in states" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Cidade">
              <b-select placeholder="Selecione uma cidade" required v-model="model.city">
                <option v-for="(item, index) in cities" :key="index" :value="item">
                  {{ item }}
                </option>
              </b-select>
            </b-field> -->
            <b-field label="Cliente">
              <b-select placeholder="Selecione um cliente" required v-model="model.customer_id">
                <option v-for="(item, index) in allCustomers" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>

            <b-field label="Centro de custo"></b-field>
            <b-field>
              <b-select placeholder="Selecione um centro de custo" required v-model="model.cost_center_id">
                <option v-for="(item, index) in getCostCenters()" :key="index" :value="item.id">
                  {{ item.code }} - {{ item.name }}
                </option>
              </b-select>
            </b-field>

            <b-field label="Localidades">
              <multiselect :options="allLocations" v-model="model.locations" :multiple="true" :close-on-select="true"
                :clear-on-select="false" :preserve-search="true" placeholder="Selecione as localidades" label="name"
                track-by="id" :preselect-first="false">
              </multiselect>
            </b-field>
            <b-field label="Observações">
              <b-input v-model="model.observation" required></b-input>
            </b-field>
            <b-button @click="save" type="button field is-info" class="mt-10">Salvar</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { EventBus } from '../event-bus.js'
import service from '../features/passengers/store/service'
import Passenger from '../models/passenger'
import StatesCities from '../assets/data/estados-cidades.json'

import debounce from 'lodash/debounce'

import axios from 'axios';

export default {
  name: 'Passengers',
  data() {
    return {
      model: Passenger.model,
      allFilterPassengers: [],
      cities: [],
      states: [],
      showDetailIcon: false,
      isCardModalActive: false,
      dataset: {
        loading: false
      },
      listAddress: []
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('customers', ['allCustomers']),
    ...mapState('passengers', ['allPassengers']),
    ...mapState('locations', ['allLocations'])
  },
  methods: {
    ...mapActions('customers', ['getAllCustomers']),
    ...mapActions('passengers', ['getAllPassengers']),
    ...mapActions('locations', ['getAllLocations']),
    async init() {
      await this.getAllCustomers()
      await this.getAllPassengers()
      await this.getAllLocations()

      this.allFilterPassengers = this.allPassengers.map((x) => {
        x.customerName = x.customer ? x.customer.name : 'N/A'
      })

      this.states = StatesCities.states.map((x) => {
        return { id: x.initials, name: x.name }
      })

      this.cities = StatesCities.states[0].cities
      this.model.city = StatesCities.states[0].cities[0]
      this.model.state = this.states[0].id
    },
    statesCities() {
      this.cities = StatesCities.states.find(
        (x) => x.initials === this.model.state
      ).cities
    },
    async save() {
      const passenger = { ...this.model }

      console.log('passenger ', passenger)

      passenger.locations = this.model.locations ? this.model.locations.map((x) => x.id) : []

      console.log('PASSENGER ', passenger)

      try {
        const save = passenger.id
          ? await service.updatePassenger(passenger)
          : await service.savePassenger(passenger)

        await this.$success('Passageiro')
        await location.reload(true)
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    onEdit(item) {
      this.model = { ...item }
    },
    async onBlockOrUnblock(passenger) {
      console.log('block passenger ', passenger)

      try {
        const result = await this.$ask(
          `Você deseja ${passenger.blocked === 1 ? 'desbloquear' : 'bloquear'
          } o passageiro?`
        )

        if (result.isConfirmed) {
          passenger.blocked = passenger.blocked === 1 ? 0 : 1

          console.log('passenger ', passenger)
          await service.updatePassenger({ id: passenger.id, ...passenger })

          await this.$success('Passageiro')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o passageiro?')

        if (result.isConfirmed) {
          await service.deletePassenger(id)

          await this.$delete('Passageiro')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },

    getAddressByName: debounce(async function (name) {
      if (!name.length) {
        this.listAddress = []
        return
      }
      // this.isFetching = true

      this.dataset.loading = true;

      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}api/v2/google-maps/find/${name}`, {
          headers: {
            'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
            'access-control-allow-headers': 'Authorization',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        })

        console.log('response ', response.data)

        this.listAddress = response.data.content;

      } catch (error) {

      } finally {
        this.dataset.loading = false;
      }
    }, 500),

    getCostCenters() {
      if (!this.model.customer_id) return;

      let customer = this.allCustomers.find((x) => x.id == this.model.customer_id);

      if (!customer || !customer.cost_center) return;

      return customer.cost_center;
    },
  },
}
</script>

<style scoped></style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
